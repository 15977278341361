import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Heading, Button, Text, Paragraph } from "grommet"
import ServiceBenefits from "../../components/servicebenefits"

import Seo from "../../components/seo"

import Layout from "../../components/layout"

export default function EmpowerYourTeam() {
  return (
    <Layout>
      <Seo title="Empower your team" />

      <Box>
        <Box
          width="xlarge"
          margin={{ top: "medium" }}
          alignSelf="center"
          align="center"
          pad={{ horizontal: "medium" }}
        >
          <Heading
            margin={{ top: "large", bottom: "none" }}
            level="1"
            size="large"
            textAlign="center"
          >
            Get ready for the future&nbsp;of&nbsp;work
          </Heading>
          <Paragraph
            size="large"
            margin={{ bottom: "xsmall" }}
            textAlign="center"
          >
            Give teams the skills they need with the Po3 Academy.
          </Paragraph>
          <Paragraph
            size="large"
            margin={{ bottom: "medium", top: "small" }}
            textAlign="center"
          >
            Develop user centered design and data capability. Empower learners
            and future&#8209;proof your organisation.
          </Paragraph>
          <Link color="blue" to="/contact" >
            <Button
              size="large"
              primary
              color="brand"
              label="Speak to the team"
            />
          </Link>
        </Box>
      </Box>
      <Box
        border={{ side: "between", color: "light-3" }}
        width="xlarge"
        alignSelf="center"
        justify="around"
        direction="row-responsive"
        gap="medium"
        margin={{ vertical: "xlarge" }}
      >
        <Box basis="1/4" align="center">
          <Text
            weight={500}
            textAlign="center"
            margin={{ top: "medium", bottom: "none" }}
            size="xlarge"
          >
            8&ndash;32
          </Text>
          <Paragraph textAlign="center" size="small">
            weeks
          </Paragraph>
        </Box>
        <Box basis="1/4" align="center">
          <Text
            weight={500}
            textAlign="center"
            margin={{ top: "medium", bottom: "none" }}
            size="xlarge"
          >
            4&ndash;8
          </Text>
          <Paragraph textAlign="center" size="small">
            hours per week
          </Paragraph>
        </Box>
        <Box basis="1/4" align="center">
          <Text
            weight={500}
            textAlign="center"
            margin={{ top: "medium", bottom: "none" }}
            size="xlarge"
          >
            25+
          </Text>
          <Paragraph textAlign="center" size="small">
            people per cohort
          </Paragraph>
        </Box>
      </Box>
      <Box width="xlarge" alignSelf="center" pad={{ horizontal: "medium" }}>
        <Box direction="row-responsive" justify="center" gap="large">
          <Box basis="1/2" margin={{ top: "medium" }}>
            <Heading level={3} margin={{ vertical: "none" }} size="medium">
              Embed the essential skills
            </Heading>
          </Box>
          <Box
            className="unbulleted"
            as="ul"
            basis="1/2"
            margin={{ top: "medium" }}
          >
            <Text as="li" size="xxlarge">
              Lean methodologies
            </Text>
            <Text as="li" size="xxlarge">
              Service Design
            </Text>
            <Text as="li" size="xxlarge">
              Storytelling
            </Text>
            <Text as="li" size="xxlarge">
              Prototyping
            </Text>
            <Text as="li" size="xxlarge">
              User research
            </Text>
            <Text as="li" size="xxlarge">
              Data analysis
            </Text>
            <Text as="li" size="xxlarge">
              Journey mapping{" "}
            </Text>
            <Text as="li" size="xxlarge">
              Business modelling
            </Text>
            <Text as="li" size="xxlarge">
              Systems thinking
            </Text>
            <Text as="li" size="xxlarge">
              Code & No-code
            </Text>
          </Box>
        </Box>

        <Box width="large" alignSelf="center" margin={{ top: "large" }}>
          <Heading
            level={3}
            margin={{ bottom: "medium" }}
            size="large"
            textAlign="center"
          >
            Mentored by experts, hackers and rebels
          </Heading>
          <Paragraph
            size="large"
            margin={{ bottom: "medium" }}
            textAlign="center"
          ></Paragraph>
        </Box>
        <Box width="xlarge" alignSelf="center" margin={{ bottom: "large" }}>
          <StaticImage
            alt="graphic"
            objectFit="cover"
            layout="fullWidth"
            placeholder="blurred"
            src="../../static/images/workshop1.jpg"
          />
        </Box>
        <Box alignSelf="center" width="xlarge">
          <ServiceBenefits  />
        </Box>
      </Box>
    </Layout>
  )
}
