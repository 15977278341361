import React from "react"
import { Link } from "gatsby"

import { Box, Heading, Paragraph } from "grommet"

import { Globe, Aggregate, UserExpert, Magic } from "grommet-icons"

export default function ServiceBenefits(data) {
  return (
    <Box
      justify="around"
      direction="row-responsive"
      gap="medium"
      margin={{ vertical: "large" }}
    >
      <Box basis="small" align="center" margin={{ bottom: "large" }}>
        <Globe color="black" size="large" />

        <Heading
          level={4}
          textAlign="center"
          margin={{ top: "medium", bottom: "none" }}
          size="small"
        >
          Remote, hybrid and&nbsp;IRL
        </Heading>
        <Paragraph textAlign="center" size="small">
        We work wherever you need us to be
        </Paragraph>
        <Link to="/contact" >Speak to the team</Link>
      </Box>
      <Box basis="small" align="center" margin={{ bottom: "large" }}>
        <Aggregate color="black" size="large" />

        <Heading
          level={4}
          textAlign="center"
          margin={{ top: "medium", bottom: "none" }}
          size="small"
        >
        Seamless team integration
        </Heading>
        <Paragraph textAlign="center" size="small">
        Multidisciplinary teams to deliver outcomes & embed knowledge
        </Paragraph>
        <Link to="/contact" >Speak to the team</Link>
      </Box>
      <Box basis="small" align="center" margin={{ bottom: "large" }}>
        <Magic color="black" size="large" />
        <Heading
          level={4}
          textAlign="center"
          margin={{ top: "medium", bottom: "none" }}
          size="small"
        >
        Real impact. Fast.
        </Heading>
        <Paragraph textAlign="center" size="small">
        Outcomes in days and weeks not months and years.
        </Paragraph>
        <Link to="/contact" >Speak to the team</Link>
      </Box>
      <Box basis="small" align="center" margin={{ bottom: "large" }}>
        <UserExpert color="black" size="large" />
        <Heading
          level={4}
          textAlign="center"
          margin={{ top: "medium", bottom: "none" }}
          size="small"
        >
        Get started today.
        </Heading>
        <Paragraph textAlign="center" size="small">
        The first part of our process is free. We’ll give you insights from day one.
        </Paragraph>
        <Link to="/contact"  >Speak to the team</Link>
      </Box>
    </Box>
  )
}
